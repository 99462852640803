<template>
  <div>
    <div>
      <h1 class="mr-sm-4 header-tablepage">CHART THEME</h1>
    </div>
    <div class="title-tabs mt-3">Chart Theme Setting</div>

    <div class="no-gutters bg-white p-3 row" v-if="isLoading">
      <div class="col-md-3 col-6">
        <div v-for="(item, index) of color" :key="index" class="mb-3">
          <ColorPicker
            :textFloat="`Color ${index + 1}`"
            v-model="item.value"
            :value="item.color"
            name="color"
          />
        </div>
      </div>

      <div class="col-md-3 col-6">
        <div v-for="item of colorFade" :key="item.id" class="mb-3">
          <ColorPicker
            :textFloat="`Color Fade ${item.id + 1}`"
            v-model="item.value"
            :value="item.color"
            name="colorFade"
          />
        </div>
      </div>
    </div>
    <FooterAction routePath="/setting" @submit="saveForm()" />
    <ModalLoading ref="modalLoading" />
  </div>
</template>
<script>
import ModalLoading from "@/components/modal/ModalLoading";
import ColorPicker from "@/components/inputs/InputColorPicker";
export default {
  components: { ModalLoading, ColorPicker },
  data() {
    return {
      form: { color: {} },
      color: [],
      colorFade: [],
      isLoading: false,
      indexFade: 0,
      index: 0,
    };
  },
  mounted() {},

  created() {
    this.getList();
  },
  destroyed() {},
  methods: {
    async getList() {
      const res = await this.axios(`/setting/chartcolor`);
      this.form.color = res.data.detail;
      this.manageColor();
      this.isLoading = true;
    },

    async manageColor() {
      for (const [key, value] of Object.entries(this.form.color)) {
        if (key.includes("fade")) {
          this.colorFade.push({
            value: value,
            id: this.indexFade,
          });

          this.indexFade++;
        } else {
          this.color.push({
            value: value,
            id: this.index,
          });

          this.index++;
        }
      }
    },

    async saveForm() {
      this.$refs.modalLoading.show();
      const result = await this.axios.post(
        `/setting/chartcolor`,
        this.form.color
      );
      if (result.data.result == 1) {
        this.successAlert().then((val) => {
          this.$store.dispatch("getChartColorList");
          this.$router.push("/setting");
        });
      } else {
        this.errorAlert(result.data.message);
      }
    },
  },
  watch: {
    color: {
      handler(newValue) {
        newValue.forEach((item) => {
          this.$set(this.form.color, `chart_color_${item.id + 1}`, item.value);
        });
      },
      deep: true,
    },
    colorFade: {
      handler(newValue) {
        newValue.forEach((item) => {
          this.$set(
            this.form.color,
            `chart_color_fade_${item.id + 1}`,
            item.value
          );
        });
      },
      deep: true,
    },
  },
};
</script>
<style></style>
